const events = [
    /*{id:"13615",
    author: "Alessandro",
      title: 'NEU: "Osterhasen" Workshop für Kinder/Erwachsene in der Stadtwerkstatt',
      start: new Date(2024, 2, 9, 14, 0),
      end: new Date(2024, 2, 9, 15, 30),
      allDay: false,
      resource: { id: 1, details: '"Hasen" Workshop für Erwachsene' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop-Allessondro2.jpg',
      hexColor:'#247b6aff',
      description:`
      Ergebnis: Kleiner Osterhase (12×12cm) 
      Empfehlung: für Kinder ab ca. 8 Jahren 


      Deine Workshop-Vorteile:
      
      1. Du brauchst nichts mitbringen. Alle Materialien und Werkzeuge sind vorhanden. Ebenso wie kleine belegte Baguettes und Getränke 🧃
      2. In der Gruppe macht es viel mehr Spaß. Gönn dir einfach einen gemütlichen Nachmittag unter Gleichgesinnten.
      3. Du bekommst alles ganz genau erklärt und erhältst sofort Antworten auf alle deine Fragen.
      4. Du kannst dein Werk selbstverständlich sofort mit nach Hause nehmen.`,
      price: '18,00',
      restAmount:10,
    },*/
    /*{ id:"13613",
      author: "Alessandro",
      title: 'NEU: "Herzen" Workshop für Kinder/Erwachsene in der Stadtwerkstatt ',
      start: new Date(2024, 3, 20, 13, 30),
      end: new Date(2024, 3, 20, 15, 0),
      allDay: false,
      resource: { id: 2, details: 'NEU: ,, Herzen " Workshop für Kinder/Erwachsene in der Stadtwerkstatt ' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +"/Workshop/Workshop_Alessandro.jpeg",
      hexColor:'#247b6aff',
      description:`
Dauer: ca. 1 - 1½h
Ergebnis: Herz (12×12cm)
Für Kinder ab ca. 8 Jahren geeignet.

Deine Workshop-Vorteile:

1. Du brauchst nichts mitbringen. Alle Materialien und Werkzeuge sind vorhanden. Ebenso wie kleine Snacks und Getränke.
2. In der Gruppe macht es viel mehr Spaß. Gönn dir einfach einen gemütlichen Nachmittag unter Gleichgesinnten.
3. Du bekommst alles ganz genau erklärt und erhältst sofort Antworten auf alle deine Fragen.
4. Du kannst dein Werk selbstverständlich sofort mit nach Hause nehmen.`,
      price: '15,00',
      restAmount:12,
    },*/
    {id:"1",
      author: "Kati",
      title: 'Weihnachtlicher Nachmittag mit Ei-der-Daus 🎄🎁',
      start: new Date(2024, 11, 1, 17, 0),
      end: new Date(2024, 11, 1, 19, 0),
      allDay: false,
      resource: { id: 2, details: 'Weihnachtlicher Nachmittag mit Ei-der-Daus 🎄🎁' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/weihnachten-eiderdaus.jpeg',
      hexColor:'#8f2d56',
      description:`
     Gestaltet Euer eigenes Ei in festlichem Glanz und verschönert es mit weihnachtlichen Bändern, funkelnden Anhängern und individuellen Designs – perfekt für die besinnliche Jahreszeit! ✨🎅

Neben kreativer Inspiration erwartet Euch eine gemütliche Atmosphäre mit leckeren Snacks, Kaffee, Tee und warmem Punsch, um Euch die winterliche Stimmung ins Herz zu zaubern.

Ein zauberhafter Nachmittag oder Abend für Freundinnen, Teams, Vereine und alle, die die Freude an kreativem Kunsthandwerk teilen – und das alles im festlichen Weihnachtszauber. 🌟

Ihr möchtet diese Veranstaltung zu einem individuellen Termin für Eure Gruppe buchen? Kontaktiert mich und wir machen Euren persönlichen Weihnachts-Workshop wahr! 🎄
      `,
      price: '40,00',
      restAmount:0,
    },
    {id:"2",
      author: "Kati",
      title: 'Weihnachtsfeier mit Ei-der-Daus 🎄🎁',
      start: new Date(2024, 11, 5, 17, 0),
      end: new Date(2024, 11, 5, 19, 0),
      allDay: false,
      resource: { id: 2, details: 'Weihnachtsfeier mit Ei-der-Daus 🎄🎁' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/weihnachten-eiderdaus.jpeg',
      hexColor:'#8f2d56',
      description:`
     Gestaltet Euer eigenes Ei in festlichem Glanz und verschönert es mit weihnachtlichen Bändern, funkelnden Anhängern und individuellen Designs – perfekt für die besinnliche Jahreszeit! ✨🎅

Neben kreativer Inspiration erwartet Euch eine gemütliche Atmosphäre mit leckeren Snacks, Kaffee, Tee und warmem Punsch, um Euch die winterliche Stimmung ins Herz zu zaubern.

Ein zauberhafter Nachmittag oder Abend für Freundinnen, Teams, Vereine und alle, die die Freude an kreativem Kunsthandwerk teilen – und das alles im festlichen Weihnachtszauber. 🌟

Ihr möchtet diese Veranstaltung zu einem individuellen Termin für Eure Gruppe buchen? Kontaktiert mich und wir machen Euren persönlichen Weihnachts-Workshop wahr! 🎄
      `,
      price: '40,00',
      restAmount:0,
    },
    {id:"13614",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 1, 15, 14, 0),
      end: new Date(2025, 1, 15, 16, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:12,
    },
    {id:"18287214",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 1, 23, 14, 0),
      end: new Date(2025, 1, 23, 16, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:12,
    },
    {id:"1828214",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 2, 14, 17, 0),
      end: new Date(2025, 2, 14, 19, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:12,
    },
    {id:"13611114",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 2, 30, 13, 0),
      end: new Date(2025, 2, 30, 15, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:9,
    },
    {id:"136141112",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 3, 5, 14, 0),
      end: new Date(2025, 3, 5, 16, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:12,
    },
    {id:"1361411251",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 3, 12, 14, 0),
      end: new Date(2025, 3, 12, 16, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:12,
    },
    {id:"136141111",
      author: "Kati",
      title: 'Ei-der-Daus: Gestaltet Euer persönliches Ei',
      start: new Date(2025, 3, 4, 16, 0),
      end: new Date(2025, 3, 4, 18, 0),
      allDay: false,
      resource: { id: 2, details: 'Ei-der-Daus: Gestaltet Euer persönliches Ei' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Workshop_Kati.jpeg',
      hexColor:'#8f2d56',
      description:`
      Gestaltet Euer eigenes Ei unter meiner Anleitung und verschönert es mit Bändern und individuellen Anhängern. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
      
      Ein schöner Nachmittag oder Abend für Freundinnen, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.


      Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich!
      `,
      price: '40,00',
      restAmount:0,
    },
    /*{
    id:"13617",
    author: "Thomas",
      title: 'Aquarell-Erlebnis für Erwachsene: Ihr Foto als Kunst',
      start: new Date(2024, 2, 23, 15, 0),
      end: new Date(2024, 2, 23, 17, 0),
      allDay: false,
      resource: { id: 1, details: 'Aquarell-Erlebnis für Erwachsene: Ihr Foto als Kunst' },
      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Karte.jpeg',
      hexColor:'#FBB13C',
      description:`
      Entdecken Sie die Kunst des Aquarells in einem gemütlichen, inspirierenden Workshop. Unter meiner Anleitung verwandeln Sie ein mitgebrachtes Foto in ein lebendiges Aquarellbild. Dieser Kurs ist perfekt für Einsteiger und Fortgeschrittene geeignet und bietet eine kurze Einführung in die Aquarelltechniken sowie individuelle Tipps, um Ihre Fertigkeiten zu verbessern.
      
Neben der praktischen Arbeit erwartet Sie eine entspannte Atmosphäre mit Snacks und Getränken, die das kreative Erlebnis abrunden. Bringen Sie einfach Ihr Lieblingsfoto mit, und ich zeige Ihnen, wie Sie es in ein persönliches Kunstwerk umsetzen können. Freuen Sie sich auf ein paar Stunden voller Kreativität und Entspannung.`,
      price: '40,00',
      restAmount:12,
    },*/
    
    {
      id:"136181",
      author: "Thomas",
      title: 'Aquarell-Erlebnis: Ihr Foto als Kunst',
        start: new Date(2024, 8, 14, 14, 0),
        end: new Date(2024, 8, 14, 16, 0),
        allDay: false,
        resource: { id: 1, details: 'Aquarell-Erlebnis: Ihr Foto als Kunst' },
        titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Eule.jpeg',
        hexColor:'#FBB13C',
        description:`
        Gestaltet Euer eigenes Aquarell unter meiner Anleitung von einem Foto Eurer Wahl. Ich zeige euch einige Tricks wie man ein Aquarell aufbaut und besondere Effekte erzielen kann. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.

Ein schöner Nachmittag oder Abend für Freunde, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.
 

Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich.`,
        price: '39,00',
        restAmount:12,
      },
      /*{
        id:"136182",
        author: "Thomas",
        title: 'Aquarell-Erlebnis: Ihr Foto als Kunst',
          start: new Date(2024, 9, 25, 18, 0),
          end: new Date(2024, 9, 25, 20, 0),
          allDay: false,
          resource: { id: 1, details: 'Aquarell-Erlebnis: Ihr Foto als Kunst' },
          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Eule.jpeg',
          hexColor:'#FBB13C',
          description:`
          Gestaltet Euer eigenes Aquarell unter meiner Anleitung von einem Foto Eurer Wahl. Ich zeige euch einige Tricks wie man ein Aquarell aufbaut und besondere Effekte erzielen kann. Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
  
  Ein schöner Nachmittag oder Abend für Freunde, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.
   
  
  Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich.`,
          price: '39,00',
          restAmount:12,
        },*/
        {
          id:"136183",
          author: "Thomas",
          title: 'Aquarell-Erlebnis: Kartengestaltung',
            start: new Date(2024, 11, 10, 18, 0),
            end: new Date(2024, 11, 10, 20, 0),
            allDay: false,
            resource: { id: 1, details: 'Aquarell-Erlebnis für Kinder: Kartengestaltung' },
            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Karte.jpeg',
            hexColor:'#FBB13C',
            description:`
            Entdecken Sie die Kunst des Aquarells in einem gemütlichen, inspirierenden Workshop. Unter meiner Anleitung verwandeln Sie ein mitgebrachtes Foto in ein lebendiges Aquarellbild. Dieser Kurs ist perfekt für Einsteiger und Fortgeschrittene geeignet und bietet eine kurze Einführung in die Aquarelltechniken sowie individuelle Tipps, um Ihre Fertigkeiten zu verbessern.
    
    Neben der praktischen Arbeit erwartet Sie eine entspannte Atmosphäre mit Snacks und Getränken, die das kreative Erlebnis abrunden. Bringen Sie einfach Ihr Lieblingsfoto mit, und ich zeige Ihnen, wie Sie es in ein persönliches Kunstwerk umsetzen können. Freuen Sie sich auf ein paar Stunden voller Kreativität und Entspannung.`,
            price: '39,00',
            restAmount:12,
          },
          {
            id:"136184",
            author: "Thomas",
            title: 'Aquarell-Erlebnis für Kinder: Kartengestaltung',
              start: new Date(2024, 11, 14, 14, 0),
              end: new Date(2024, 11, 14, 16, 0),
              allDay: false,
              resource: { id: 1, details: 'Aquarell-Erlebnis für Kinder: Kartengestaltung' },
              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Karte.jpeg',
              hexColor:'#FBB13C',
              description:`
              Entdecken Sie die Kunst des Aquarells in einem gemütlichen, inspirierenden Workshop. Unter meiner Anleitung verwandeln Sie ein mitgebrachtes Foto in ein lebendiges Aquarellbild. Dieser Kurs ist perfekt für Einsteiger und Fortgeschrittene geeignet und bietet eine kurze Einführung in die Aquarelltechniken sowie individuelle Tipps, um Ihre Fertigkeiten zu verbessern.
      
      Neben der praktischen Arbeit erwartet Sie eine entspannte Atmosphäre mit Snacks und Getränken, die das kreative Erlebnis abrunden. Bringen Sie einfach Ihr Lieblingsfoto mit, und ich zeige Ihnen, wie Sie es in ein persönliches Kunstwerk umsetzen können. Freuen Sie sich auf ein paar Stunden voller Kreativität und Entspannung.`,
              price: '39,00',
              restAmount:12,
            },
            {
              id:"136185",
              author: "Thomas",
              title: 'Kohle-Skizzieren in der Stadt',
                start: new Date(2024, 7, 28, 18, 0),
                end: new Date(2024, 7, 28, 20, 0),
                allDay: false,
                resource: { id: 1, details: 'Kohle-Skizzieren in der Stadt' },
                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Kohlebild.jpeg',
                hexColor:'#FBB13C',
                description:`
               Kommt mit mir durch Bautzen und lasst uns ein Kunstwerk in Skizzenformat mit Kohle malen. Bei schönem Wetter durchlaufen wir die Altstadt und fertigen kleine Kunstwerke an.
 
Ein schöner Tag oder Abend für Freunde, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.
 

Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich.`,
                price: '25,00',
                restAmount:12,
              },
              {
                id:"136186",
                author: "Thomas",
                title: 'Kohle-Skizzieren in der Stadt',
                  start: new Date(2024, 10, 9, 10, 0),
                  end: new Date(2024, 10, 9, 12, 0),
                  allDay: false,
                  resource: { id: 1, details: 'Kohle-Skizzieren in der Stadt' },
                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Kohlebild-Workshop.jpeg',
                  hexColor:'#FBB13C',
                  description:`
                 Kommt mit mir durch Bautzen und lasst uns ein Kunstwerk in Skizzenformat mit Kohle malen. Bei schönem Wetter durchlaufen wir die Altstadt und fertigen kleine Kunstwerke an.
 
Ein schöner Tag oder Abend für Freunde, Teams, Vereine und natürlich alle, die dieses Kunsthandwerk begeistert.
 

Ihr möchtet diese Veranstaltung mit individuellem Termin für eure Gruppe buchen - dann kontaktiert mich.`,
                  price: '25,00',
                  restAmount:12,
                },
      
      /*{
        id:"13655",
        author: "Carina",
        title: 'Nähkurs für Anfänger',
          start: new Date(2024, 3, 6, 12, 0),
          end: new Date(2024, 3, 6, 14, 0),
          allDay: false,
          resource: { id: 1, details: 'Nähkurs für Anfänger' },
          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/naehkurs.jpeg',
          hexColor:'#Ffa500',
          description:`
Du nähst an Deiner eigenen Nähmaschine unter meiner 
Anleitung eine Kissenhülle Deiner Wahl.
Als Material stelle ich Dir vorgefertigte Quadrate aus BioJacquard in 
verschiedenen Farben und Mustern zur Verfügung.
Du lernst in diesem Kurs Nähgrundlagen, die Dir in Alltag helfen, um 
Kleidung oder Deko zu ändern, zu reparieren oder einfach Projekte selbst 
herzustellen.
Wenn Du hast, bringe Deine Nähmaschine mit Garn und eine Stretchnadel 
mit. Alle weiteren Nähutensilien und Getränke sind inklusive.
Ich freue mich auf Dich! Carina ☀️`,
          price: '40,00',
          restAmount:8,
        },*/
        /*{
          id:"13415",
          author: "Carina",
          title: 'Nähkurs für Anfänger',
            start: new Date(2024, 4, 25, 12, 0),
            end: new Date(2024, 4, 25, 14, 0),
            allDay: false,
            resource: { id: 1, details: 'Nähkurs für Anfänger' },
            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/naehkurs.jpeg',
            hexColor:'#Ffa500',
            description:`
  Du nähst an Deiner eigenen Nähmaschine unter meiner 
  Anleitung eine Kissenhülle Deiner Wahl.
  Als Material stelle ich Dir vorgefertigte Quadrate aus BioJacquard in 
  verschiedenen Farben und Mustern zur Verfügung.
  Du lernst in diesem Kurs Nähgrundlagen, die Dir in Alltag helfen, um 
  Kleidung oder Deko zu ändern, zu reparieren oder einfach Projekte selbst 
  herzustellen.
  Wenn Du hast, bringe Deine Nähmaschine mit Garn und eine Stretchnadel 
  mit. Alle weiteren Nähutensilien und Getränke sind inklusive.
  Ich freue mich auf Dich! Carina ☀️`,
            price: '40,00',
            restAmount:8,
          },*/
           /* {
          id:"188415",
          author: "Steffi",
          title: 'Töpfer dein persönliches Unikat',
            start: new Date(2024, 7, 2, 18, 0),
            end: new Date(2024, 7, 2, 20, 30),
            allDay: false,
            resource: { id: 1, details: 'Töpfer dein persönliches Unikat' },
            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/toepferkurs.jpeg',
            hexColor:'#Ffa500',
            description:`
            Kreiert Eure eigenen wundervollen Einzelstücke. Wir verwenden Ton für den Hochbrand, somit sind Eure Kunstwerke frostsicher und spülmaschinenfest.
            Wir modellieren gemeinsam Tassen, Schalen, Becher etc....
            Diese brenne & glasiere ich dann nach Euren Vorstellungen. Eine Auswahl an Glasurmustern wird im Workshop ausliegen.
            Wer selber glasieren möchte, kann dazu gern einen anderen Termin mit mir vereinbaren. 
            Abholbereit werden Eure Werke dann ca. 10 Tage später sein. Abholung ist dann in der StadtWERKSTATT 😉
            Ich freue mich auf Euch!

            `,
            price: '39,00',
            restAmount:8,
          },
          {
            id:"187415",
            author: "Steffi",
            title: 'Töpfer dein persönliches Unikat',
              start: new Date(2024, 8, 6, 18, 0),
              end: new Date(2024, 8, 6, 20, 30),
              allDay: false,
              resource: { id: 1, details: 'Töpfer dein persönliches Unikat' },
              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/toepferkurs.jpeg',
              hexColor:'#Ffa500',
              description:`
              Kreiert Eure eigenen wundervollen Einzelstücke. Wir verwenden Ton für den Hochbrand, somit sind Eure Kunstwerke frostsicher und spülmaschinenfest.
              Wir modellieren gemeinsam Tassen, Schalen, Becher etc....
              Diese brenne & glasiere ich dann nach Euren Vorstellungen. Eine Auswahl an Glasurmustern wird im Workshop ausliegen.
              Wer selber glasieren möchte, kann dazu gern einen anderen Termin mit mir vereinbaren. 
              Abholbereit werden Eure Werke dann ca. 10 Tage später sein. Abholung ist dann in der StadtWERKSTATT 😉
              Ich freue mich auf Euch!
  
              `,
              price: '39,00',
              restAmount:8,
            },
            {
              id:"186415",
              author: "Steffi",
              title: 'Töpfer dein persönliches Unikat',
                start: new Date(2024, 9, 4, 18, 0),
                end: new Date(2024, 9, 4, 20, 30),
                allDay: false,
                resource: { id: 1, details: 'Töpfer dein persönliches Unikat' },
                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/toepferkurs.jpeg',
                hexColor:'#Ffa500',
                description:`
                Kreiert Eure eigenen wundervollen Einzelstücke. Wir verwenden Ton für den Hochbrand, somit sind Eure Kunstwerke frostsicher und spülmaschinenfest.
                Wir modellieren gemeinsam Tassen, Schalen, Becher etc....
                Diese brenne & glasiere ich dann nach Euren Vorstellungen. Eine Auswahl an Glasurmustern wird im Workshop ausliegen.
                Wer selber glasieren möchte, kann dazu gern einen anderen Termin mit mir vereinbaren. 
                Abholbereit werden Eure Werke dann ca. 10 Tage später sein. Abholung ist dann in der StadtWERKSTATT 😉
                Ich freue mich auf Euch!
    
                `,
                price: '39,00',
                restAmount:8,
              },
              {
                id:"185415",
                author: "Steffi",
                title: 'Töpfer dein persönliches Unikat',
                  start: new Date(2024, 10, 1, 18, 0),
                  end: new Date(2024, 10, 1, 20, 30),
                  allDay: false,
                  resource: { id: 1, details: 'Töpfer dein persönliches Unikat' },
                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/toepferkurs.jpeg',
                  hexColor:'#Ffa500',
                  description:`
                  Kreiert Eure eigenen wundervollen Einzelstücke. Wir verwenden Ton für den Hochbrand, somit sind Eure Kunstwerke frostsicher und spülmaschinenfest.
                  Wir modellieren gemeinsam Tassen, Schalen, Becher etc....
                  Diese brenne & glasiere ich dann nach Euren Vorstellungen. Eine Auswahl an Glasurmustern wird im Workshop ausliegen.
                  Wer selber glasieren möchte, kann dazu gern einen anderen Termin mit mir vereinbaren. 
                  Abholbereit werden Eure Werke dann ca. 10 Tage später sein. Abholung ist dann in der StadtWERKSTATT 😉
                  Ich freue mich auf Euch!
      
                  `,
                  price: '39,00',
                  restAmount:8,
                },
                {
                  id:"184415",
                  author: "Steffi",
                  title: 'Töpfer dein persönliches Unikat',
                    start: new Date(2024, 11, 6, 18, 0),
                    end: new Date(2024, 11, 6, 20, 30),
                    allDay: false,
                    resource: { id: 1, details: 'Töpfer dein persönliches Unikat' },
                    titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/toepferkurs.jpeg',
                    hexColor:'#Ffa500',
                    description:`
                    Kreiert Eure eigenen wundervollen Einzelstücke. Wir verwenden Ton für den Hochbrand, somit sind Eure Kunstwerke frostsicher und spülmaschinenfest.
                    Wir modellieren gemeinsam Tassen, Schalen, Becher etc....
                    Diese brenne & glasiere ich dann nach Euren Vorstellungen. Eine Auswahl an Glasurmustern wird im Workshop ausliegen.
                    Wer selber glasieren möchte, kann dazu gern einen anderen Termin mit mir vereinbaren. 
                    Abholbereit werden Eure Werke dann ca. 10 Tage später sein. Abholung ist dann in der StadtWERKSTATT 😉
                    Ich freue mich auf Euch!
        
                    `,
                    price: '39,00',
                    restAmount:8,
                  },*/
                  {
                    id:"184415518",
                    author: "Sarah",
                    title: 'Verschicke Deine eigene Karte - Kartengestaltung',
                      start: new Date(2025, 0, 27, 18, 0),
                      end: new Date(2025, 0, 27, 20, 0),
                      allDay: false,
                      resource: { id: 1, details: 'Verschicke Deine eigene Karte - Kartengestaltung' },
                      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-karten.jpeg',
                      hexColor:'#Ffa500',
                      description:`
                      Dieses Jahr möchtest Du Deinen Lieben mit einer selbst gestalteten Karte eine Freude
bereiten?
Sogar einen vergrößerten Abzug deiner Karte kannst Du als Bild verschenken.
Wie das geht, erlernst Du bei mir im Kurs.
Wir arbeiten mit Aquarellfarben in Kombination mit Zeichnung.
Bring zum Kurs ein Lieblingsmotiv oder Lieblingsfoto mit und wir verarbeiten es kreativ zum
Motiv auf Deiner Karte oder Deinem Print. Es stehen auch Ideen zur Motivwahl und zu
unterschiedlichen Themen bereit.
Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive
          
                      `,
                      price: '40,00',
                      restAmount:12,
                    },
                    {
                      id:"1844117",
                      author: "Sarah",
                      title: 'Deine Geschichte in Bildern - Erzählsteine Bemalen',
                        start: new Date(2024, 9, 30, 18, 0),
                        end: new Date(2024, 9, 30, 20, 0),
                        allDay: false,
                        resource: { id: 1, details: 'Deine Geschichte in Bildern - Erzählsteine Bemalen' },
                        titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-steine.jpeg',
                        hexColor:'#Ffa500',
                        description:`
                        In diesem Kurs stellen wir Erzählsteine her. Diese können in der Sprachförderung und im
Sprachenlernen eingesetzt werden.
Im Kurs erhältst Du Steine, die Du mit Motiven bemalst.
Es stehen verschiedene Ideen zu Motiven und Themen bereit, aber Du kannst auch sehr
gern eine Geschichte mitbringen, zu der Du Deine eigenen Erzählsteine herstellst.
Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
            
                        `,
                        price: '40,00',
                        restAmount:12,
                      },
                      {
                        id:"184415515",
                        author: "Sarah",
                        title: 'Verschicke Deine eigene Karte - Kartengestaltung',
                          start: new Date(2025, 1, 12, 18, 0),
                          end: new Date(2025, 1, 12, 20, 0),
                          allDay: false,
                          resource: { id: 1, details: 'Verschicke Deine eigene Karte - Kartengestaltung' },
                          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-karten.jpeg',
                          hexColor:'#Ffa500',
                          description:`
                          Dieses Jahr möchtest Du Deinen Lieben mit einer selbst gestalteten Karte eine Freude
    bereiten?
    Sogar einen vergrößerten Abzug deiner Karte kannst Du als Bild verschenken.
    Wie das geht, erlernst Du bei mir im Kurs.
    Wir arbeiten mit Aquarellfarben in Kombination mit Zeichnung.
    Bring zum Kurs ein Lieblingsmotiv oder Lieblingsfoto mit und wir verarbeiten es kreativ zum
    Motiv auf Deiner Karte oder Deinem Print. Es stehen auch Ideen zur Motivwahl und zu
    unterschiedlichen Themen bereit.
    Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive
              
                          `,
                          price: '40,00',
                          restAmount:12,
                        },
                        {
                          id:"184415514",
                          author: "Sarah",
                          title: 'Deine Geschichte in Bildern - Erzählsteine Bemalen',
                            start: new Date(2024, 10, 13, 17, 0),
                            end: new Date(2024, 10, 13, 19,0),
                            allDay: false,
                            resource: { id: 1, details: 'Deine Geschichte in Bildern - Erzählsteine Bemalen' },
                            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-steine.jpeg',
                            hexColor:'#Ffa500',
                            description:`
                            In diesem Kurs stellen wir Erzählsteine her. Diese können in der Sprachförderung und im
    Sprachenlernen eingesetzt werden.
    Im Kurs erhältst Du Steine, die Du mit Motiven bemalst.
    Es stehen verschiedene Ideen zu Motiven und Themen bereit, aber Du kannst auch sehr
    gern eine Geschichte mitbringen, zu der Du Deine eigenen Erzählsteine herstellst.
    Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
                
                            `,
                            price: '40,00',
                            restAmount:12,
                          },
                          {
                            id:"1844155122",
                            author: "Sarah",
                            title: 'Verschicke Deine eigene Karte - Kartengestaltung',
                            start: new Date(2025, 1, 26, 18, 0),
                            end: new Date(2025, 1, 26, 20,0),
                              allDay: false,
                              resource: { id: 1, details: 'Verschicke Deine eigene Karte - Kartengestaltung' },
                              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-karten.jpeg',
                              hexColor:'#Ffa500',
                              description:`
                              Dieses Jahr möchtest Du Deinen Lieben mit einer selbst gestalteten Karte eine Freude
        bereiten?
        Sogar einen vergrößerten Abzug deiner Karte kannst Du als Bild verschenken.
        Wie das geht, erlernst Du bei mir im Kurs.
        Wir arbeiten mit Aquarellfarben in Kombination mit Zeichnung.
        Bring zum Kurs ein Lieblingsmotiv oder Lieblingsfoto mit und wir verarbeiten es kreativ zum
        Motiv auf Deiner Karte oder Deinem Print. Es stehen auch Ideen zur Motivwahl und zu
        unterschiedlichen Themen bereit.
        Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive
                  
                              `,
                              price: '40,00',
                              restAmount:12,
                            },
                            {
                              id:"184415511",
                              author: "Sarah",
                              title: 'Deine Geschichte in Bildern - Erzählsteine Bemalen',
                                start: new Date(2024, 11, 16, 18, 0),
                                end: new Date(2024, 11, 16, 20,0),
                                allDay: false,
                                resource: { id: 1, details: 'Deine Geschichte in Bildern - Erzählsteine Bemalen' },
                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-steine.jpeg',
                                hexColor:'#Ffa500',
                                description:`
                                In diesem Kurs stellen wir Erzählsteine her. Diese können in der Sprachförderung und im
        Sprachenlernen eingesetzt werden.
        Im Kurs erhältst Du Steine, die Du mit Motiven bemalst.
        Es stehen verschiedene Ideen zu Motiven und Themen bereit, aber Du kannst auch sehr
        gern eine Geschichte mitbringen, zu der Du Deine eigenen Erzählsteine herstellst.
        Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive.
                    
                                `,
                                price: '40,00',
                                restAmount:12,
                              },
                              {
                                id:"184415533",
                                author: "Sarah",
                                title: 'Verschicke Deine eigene Karte - Kartengestaltung',
                                start: new Date(2025, 2, 19, 18, 0),
                                end: new Date(2025, 2, 19, 20,0),
                                  allDay: false,
                                  resource: { id: 1, details: 'Verschicke Deine eigene Karte - Kartengestaltung' },
                                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-karten.jpeg',
                                  hexColor:'#Ffa500',
                                  description:`
                                  Dieses Jahr möchtest Du Deinen Lieben mit einer selbst gestalteten Karte eine Freude
            bereiten?
            Sogar einen vergrößerten Abzug deiner Karte kannst Du als Bild verschenken.
            Wie das geht, erlernst Du bei mir im Kurs.
            Wir arbeiten mit Aquarellfarben in Kombination mit Zeichnung.
            Bring zum Kurs ein Lieblingsmotiv oder Lieblingsfoto mit und wir verarbeiten es kreativ zum
            Motiv auf Deiner Karte oder Deinem Print. Es stehen auch Ideen zur Motivwahl und zu
            unterschiedlichen Themen bereit.
            Zum Workshop sind ein paar kleine Snacks, Kaffee, Tee und Mineralwasser inklusive
                      
                                  `,
                                  price: '40,00',
                                  restAmount:12,
                                },
                               
                                  {
                                    id:"184415522339",
                                    author: "Corinna",
                                    title: 'Naturkosmetik einfach selber machen',
                                      start: new Date(2025, 3, 24, 18, 0),
                                      end: new Date(2025, 3, 24, 20,0),
                                      allDay: false,
                                      resource: { id: 1, details: 'Naturkosmetik einfach selber machen' },
                                      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-kosmetik.jpeg',
                                      hexColor:'#00CED1',
                                      description:`
                                       Naturkosmetik wird für immer mehr
   Menschen die Alternative zur Kosmetik
   aus der Drogerie. 
  Du lernst verschieden
   Salbengrundlagen und deren
   Wirkungsweisen kennen. Mit
   aromatischen Kräutern aus dem
   eigenen Garten und duftenden Ölen
   stellen wir natürlich pflegende
   Kosmetik her. Aus den Ansatzölen
   werden Creme und Körperbutter, Deo
   und Zahncreme kreieren wir mit
   altbekannten Hausmitteln. 
  

   Mein Name ist
   Corinna Fischer
   seit fast 30 Jahren ist natürliche
   Gesundheit mein Arbeits- und
   Lebensinhalt. Als ausgebildete
   medizinische Kosmetikerin bin ich mit
   der Herstellung von Kosmetika
   vertraut, meine physiotherapeutische
   Ausbildung vertieft diese Anwendungen
   zur ganzheitlichen Gesunderhaltung.
                          
                                      `,
                                      price: '98,00',
                                      restAmount:12,
                                    },
                                    {
                                      id:"18441552266",
                                      author: "Corinna",
                                      title: 'Naturkosmetik einfach selber machen',
                                        start: new Date(2025, 9, 24, 18, 0),
                                        end: new Date(2025, 9, 24, 20,0),
                                        allDay: false,
                                        resource: { id: 1, details: 'Naturkosmetik einfach selber machen' },
                                        titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-kosmetik.jpeg',
                                        hexColor:'#00CED1',
                                        description:`
                                         Naturkosmetik wird für immer mehr
     Menschen die Alternative zur Kosmetik
     aus der Drogerie. 
    Du lernst verschieden
     Salbengrundlagen und deren
     Wirkungsweisen kennen. Mit
     aromatischen Kräutern aus dem
     eigenen Garten und duftenden Ölen
     stellen wir natürlich pflegende
     Kosmetik her. Aus den Ansatzölen
     werden Creme und Körperbutter, Deo
     und Zahncreme kreieren wir mit
     altbekannten Hausmitteln. 
    
     
     Mein Name ist
     Corinna Fischer
     seit fast 30 Jahren ist natürliche
     Gesundheit mein Arbeits- und
     Lebensinhalt. Als ausgebildete
     medizinische Kosmetikerin bin ich mit
     der Herstellung von Kosmetika
     vertraut, meine physiotherapeutische
     Ausbildung vertieft diese Anwendungen
     zur ganzheitlichen Gesunderhaltung.
                            
                                        `,
                                        price: '98,00',
                                        restAmount:12,
                                      },
                                      {
                                        id:"184415522662",
                                        author: "Corinna",
                                        title: 'Heilsalben & Tinkturen',
                                          start: new Date(2025, 0, 23, 18, 0),
                                          end: new Date(2025, 0, 23, 20,0),
                                          allDay: false,
                                          resource: { id: 1, details: 'Heilsalben & Tinkturen' },
                                          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/heilkunde.jpeg',
                                          hexColor:'#00CED1',
                                          description:`
                                           Wertvolle Heilkräuter in einer
 kraftvollen Heilsalbe und wirksame
 Tinkturen wie Oxymel sind ein
 Geschenk der Natur. Diese selbst
 herzustellen verbindet uns wieder mit
 uns selbst und schenkt uns
 Gesundheit und Wohlergehen.
 

 
 Inhalt dieser DenkWerkstatt:
 - Heilsalbe gegen Schmerzen
 - Natürliches Antibiotikum
 - Stärkende Tinktur
 - Heilsalbe bei Hautproblemen
 - NACHTKERZENSALBE
 - HAGEBUTTENOXYMEL
 - ARNIKASALBE
 - SCHWEDENKRÄUTER


 Die besondere Wirkung dieser
 Heilsalben und Tinkuteren
 werden bei der Anwendung
 sichtbar. Natürliche Zutaten,
 schönende Herstellung und die
 persönliche Hingabe gestalten
 außergewöhnliche Heilschätze.
                              
                                          `,
                                          price: '98,00',
                                          restAmount:12,
                                        },
                                        {
                                          id:"184415522661",
                                          author: "Corinna",
                                          title: 'Naturkosmetik einfach selber machen',
                                            start: new Date(2025, 2, 20, 18, 0),
                                            end: new Date(2025, 2, 20, 20,0),
                                            allDay: false,
                                            resource: { id: 1, details: 'Naturkosmetik einfach selber machen' },
                                            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-kosmetik.jpeg',
                                            hexColor:'#00CED1',
                                            description:`
                                             Naturkosmetik wird für immer mehr
         Menschen die Alternative zur Kosmetik
         aus der Drogerie. 
        Du lernst verschieden
         Salbengrundlagen und deren
         Wirkungsweisen kennen. Mit
         aromatischen Kräutern aus dem
         eigenen Garten und duftenden Ölen
         stellen wir natürlich pflegende
         Kosmetik her. Aus den Ansatzölen
         werden Creme und Körperbutter, Deo
         und Zahncreme kreieren wir mit
         altbekannten Hausmitteln. 
        
         
         Mein Name ist
         Corinna Fischer
         seit fast 30 Jahren ist natürliche
         Gesundheit mein Arbeits- und
         Lebensinhalt. Als ausgebildete
         medizinische Kosmetikerin bin ich mit
         der Herstellung von Kosmetika
         vertraut, meine physiotherapeutische
         Ausbildung vertieft diese Anwendungen
         zur ganzheitlichen Gesunderhaltung.
                                
                                            `,
                                            price: '98,00',
                                            restAmount:12,
                                          },
                                          {
                                            id:"1844155226621",
                                            author: "Corinna",
                                            title: 'Heilsalben & Tinkturen',
                                              start: new Date(2025, 3, 10, 18, 0),
                                              end: new Date(2025, 3, 10, 20,0),
                                              allDay: false,
                                              resource: { id: 1, details: 'Heilsalben & Tinkturen' },
                                              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/heilkunde.jpeg',
                                              hexColor:'#00CED1',
                                              description:`
                                               Wertvolle Heilkräuter in einer
     kraftvollen Heilsalbe und wirksame
     Tinkturen wie Oxymel sind ein
     Geschenk der Natur. Diese selbst
     herzustellen verbindet uns wieder mit
     uns selbst und schenkt uns
     Gesundheit und Wohlergehen.
     
    
     
     Inhalt dieser DenkWerkstatt:
     - Heilsalbe gegen Schmerzen
     - Natürliches Antibiotikum
     - Stärkende Tinktur
     - Heilsalbe bei Hautproblemen
     - NACHTKERZENSALBE
     - HAGEBUTTENOXYMEL
     - ARNIKASALBE
     - SCHWEDENKRÄUTER
    
    
     Die besondere Wirkung dieser
     Heilsalben und Tinkuteren
     werden bei der Anwendung
     sichtbar. Natürliche Zutaten,
     schönende Herstellung und die
     persönliche Hingabe gestalten
     außergewöhnliche Heilschätze.
                                  
                                              `,
                                              price: '98,00',
                                              restAmount:12,
                                            },
                                            {
                                              id:"1844155226622",
                                              author: "Corinna",
                                              title: 'Heilsalben & Tinkturen',
                                                start: new Date(2025, 7, 14, 18, 0),
                                                end: new Date(2025, 7, 14, 20,0),
                                                allDay: false,
                                                resource: { id: 1, details: 'Heilsalben & Tinkturen' },
                                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/heilkunde.jpeg',
                                                hexColor:'#00CED1',
                                                description:`
                                                 Wertvolle Heilkräuter in einer
       kraftvollen Heilsalbe und wirksame
       Tinkturen wie Oxymel sind ein
       Geschenk der Natur. Diese selbst
       herzustellen verbindet uns wieder mit
       uns selbst und schenkt uns
       Gesundheit und Wohlergehen.
       
      
       
       Inhalt dieser DenkWerkstatt:
       - Heilsalbe gegen Schmerzen
       - Natürliches Antibiotikum
       - Stärkende Tinktur
       - Heilsalbe bei Hautproblemen
       - NACHTKERZENSALBE
       - HAGEBUTTENOXYMEL
       - ARNIKASALBE
       - SCHWEDENKRÄUTER
      
      
       Die besondere Wirkung dieser
       Heilsalben und Tinkuteren
       werden bei der Anwendung
       sichtbar. Natürliche Zutaten,
       schönende Herstellung und die
       persönliche Hingabe gestalten
       außergewöhnliche Heilschätze.
                                    
                                                `,
                                                price: '98,00',
                                                restAmount:12,
                                              },
                                              {
                                                id:"1844155226623",
                                                author: "Corinna",
                                                title: 'Heilsalben & Tinkturen',
                                                  start: new Date(2025, 10, 13, 18, 0),
                                                  end: new Date(2025, 10, 13, 20,0),
                                                  allDay: false,
                                                  resource: { id: 1, details: 'Heilsalben & Tinkturen' },
                                                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/heilkunde.jpeg',
                                                  hexColor:'#00CED1',
                                                  description:`
                                                   Wertvolle Heilkräuter in einer
         kraftvollen Heilsalbe und wirksame
         Tinkturen wie Oxymel sind ein
         Geschenk der Natur. Diese selbst
         herzustellen verbindet uns wieder mit
         uns selbst und schenkt uns
         Gesundheit und Wohlergehen.
         
        
         
         Inhalt dieser DenkWerkstatt:
         - Heilsalbe gegen Schmerzen
         - Natürliches Antibiotikum
         - Stärkende Tinktur
         - Heilsalbe bei Hautproblemen
         - NACHTKERZENSALBE
         - HAGEBUTTENOXYMEL
         - ARNIKASALBE
         - SCHWEDENKRÄUTER
        
        
         Die besondere Wirkung dieser
         Heilsalben und Tinkuteren
         werden bei der Anwendung
         sichtbar. Natürliche Zutaten,
         schönende Herstellung und die
         persönliche Hingabe gestalten
         außergewöhnliche Heilschätze.
                                      
                                                  `,
                                                  price: '98,00',
                                                  restAmount:12,
                                                },
                                                {
                                                  id:"1844155226624",
                                                  author: "Corinna",
                                                  title: 'Oxymel - Natürlicher Stoffwechsel',
                                                    start: new Date(2025, 4, 15, 18, 0),
                                                    end: new Date(2025, 4, 15, 20,0),
                                                    allDay: false,
                                                    resource: { id: 1, details: 'Oxymel - Natürlicher Stoffwechsel' },
                                                    titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Oxymel.png',
                                                    hexColor:'#00CED1',
                                                    description:`
                                                     Herstellung von Oxymel für eine natürlichen Stoffwechselförderung


                                                     Das fermentierte Naturprodukt Essig hat eine anregende Wirkung auf den Stoffwechsel und reguliert auf angenehme Weise viele körpereigene Prozesse. Ein wertvolles Antioxidans das für ein starkes Immunsystem sorgt und den Verdauungsapparat gesund hält.

                                        
                                                    `,
                                                    price: '98,00',
                                                    restAmount:12,
                                                  },
                                                  {
                                                    id:"1844155226625",
                                                    author: "Corinna",
                                                    title: 'Oxymel - Natürlicher Stoffwechsel',
                                                      start: new Date(2025, 7, 28, 18, 0),
                                                      end: new Date(2025, 7, 28, 20,0),
                                                      allDay: false,
                                                      resource: { id: 1, details: 'Oxymel - Natürlicher Stoffwechsel' },
                                                      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Oxymel.png',
                                                      hexColor:'#00CED1',
                                                      description:`
                                                       Herstellung von Oxymel für eine natürlichen Stoffwechselförderung
  
  
                                                       Das fermentierte Naturprodukt Essig hat eine anregende Wirkung auf den Stoffwechsel und reguliert auf angenehme Weise viele körpereigene Prozesse. Ein wertvolles Antioxidans das für ein starkes Immunsystem sorgt und den Verdauungsapparat gesund hält.
  
                                          
                                                      `,
                                                      price: '98,00',
                                                      restAmount:12,
                                                    },
                                                    {
                                                      id:"1844155226626",
                                                      author: "Corinna",
                                                      title: 'Oxymel - Natürlicher Stoffwechsel',
                                                        start: new Date(2025, 10, 27, 18, 0),
                                                        end: new Date(2025, 10, 27, 20,0),
                                                        allDay: false,
                                                        resource: { id: 1, details: 'Oxymel - Natürlicher Stoffwechsel' },
                                                        titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Oxymel.png',
                                                        hexColor:'#00CED1',
                                                        description:`
                                                         Herstellung von Oxymel für eine natürlichen Stoffwechselförderung
    
    
                                                         Das fermentierte Naturprodukt Essig hat eine anregende Wirkung auf den Stoffwechsel und reguliert auf angenehme Weise viele körpereigene Prozesse. Ein wertvolles Antioxidans das für ein starkes Immunsystem sorgt und den Verdauungsapparat gesund hält.
    
                                            
                                                        `,
                                                        price: '98,00',
                                                        restAmount:12,
                                                      },
                                                      {
                                                        id:"1844155226627",
                                                        author: "Corinna",
                                                        title: 'Traditionelle Europäische Medizin',
                                                          start: new Date(2025, 9, 17, 18, 0),
                                                          end: new Date(2025, 9, 17, 20,0),
                                                          allDay: false,
                                                          resource: { id: 1, details: 'Traditionelle Europäische Medizin' },
                                                          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Oxymel.png',
                                                          hexColor:'#00CED1',
                                                          description:`
                                                           Kurs zur Traditionelle Europäische Medizin
                                              
                                                          `,
                                                          price: '98,00',
                                                          restAmount:12,
                                                        },
                                      {
                                        id:"18441552231139",
                                        author: "Kathrin",
                                        title: 'Keramikkurs Engel',
                                          start: new Date(2024, 10, 26, 16, 0),
                                          end: new Date(2024, 10, 26, 18,0),
                                          allDay: false,
                                          resource: { id: 1, details: 'Naturkosmetik einfach selber machen' },
                                          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-Engel.jpeg',
                                          hexColor:'#800080',
                                          description:`
                                          Fertige deinen eigenen kleinen Schutzengel für dich, für einen lieben Menschen oder als ganz persönliches Geschenk zu Weihnachten. Mit einigen Tricks kannst du deinem Engel aus Keramik Persönlichkeit und Charme einhauchen. Ich zeige dir wie ... 
Kaffee, Tee und Mineralwasser stehen  inklusive zur Verfügung.  Weiter Absprachen können gern getroffen werden.
                                          `,
                                          price: '35,00',
                                          restAmount:0,
                                        },
                                        {
                                          id:"19",
                                          author: "Kathrin",
                                          title: 'Keramikkurs Minischalen',
                                            start: new Date(2025, 1, 14, 16, 0),
                                            end: new Date(2025, 1, 14, 18,0),
                                            allDay: false,
                                            resource: { id: 1, details: 'Keramikkurs Minischalen' },
                                            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop_Schalen.jpg',
                                            hexColor:'#800080',
                                            description:`
                                            Fertige kleine Minischalen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du einen cleanen oder einen verspielten Look kreieren, der ein kleines Schälchen zu einem zauberhaften Blickfang werden lässt. Ich zeige dir wie... 
Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weitere terminliche und inhaltliche Absprachen können gern getroffen werden.
                                            `,
                                            price: '39,00',
                                            restAmount:10,
                                          },
                                          {
                                            id:"195",
                                            author: "Kathrin",
                                            title: 'Keramikkurs Minischalen',
                                              start: new Date(2025, 1, 19, 16, 0),
                                              end: new Date(2025, 1, 19, 18,0),
                                              allDay: false,
                                              resource: { id: 1, details: 'Keramikkurs Minischalen' },
                                              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop_Schalen.jpg',
                                              hexColor:'#800080',
                                              description:`
                                              Fertige kleine Minischalen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du einen cleanen oder einen verspielten Look kreieren, der ein kleines Schälchen zu einem zauberhaften Blickfang werden lässt. Ich zeige dir wie... 
  Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weitere terminliche und inhaltliche Absprachen können gern getroffen werden.
                                              `,
                                              price: '39,00',
                                              restAmount:10,
                                            },
                                            {
                                              id:"199",
                                              author: "Kathrin",
                                              title: 'Keramikkurs Minischalen',
                                                start: new Date(2025, 1, 20, 16, 0),
                                                end: new Date(2025, 1, 20, 18,0),
                                                allDay: false,
                                                resource: { id: 1, details: 'Keramikkurs Minischalen' },
                                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop_Schalen.jpg',
                                                hexColor:'#800080',
                                                description:`
                                                Fertige kleine Minischalen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du einen cleanen oder einen verspielten Look kreieren, der ein kleines Schälchen zu einem zauberhaften Blickfang werden lässt. Ich zeige dir wie... 
    Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weitere terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                `,
                                                price: '39,00',
                                                restAmount:10,
                                              },
                                          {
                                            id:"891",
                                            author: "Kathrin",
                                            title: 'Keramikkurs Eulen',
                                              start: new Date(2025, 2, 13, 16, 0),
                                              end: new Date(2025, 2, 13, 18,0),
                                              allDay: false,
                                              resource: { id: 1, details: 'Keramikkurs Eulen' },
                                              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-eule.jpeg',
                                              hexColor:'#800080',
                                              description:`
                                              Fertige kleine Keramikeulen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine süße Nachteule oder einen kleinen Kautz ganz nach deinem Geschmack formen. Ich zeige Dir wie... 
Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                              `,
                                              price: '39,00',
                                              restAmount:10,
                                            },
                                            {
                                              id:"892",
                                              author: "Kathrin",
                                              title: 'Keramikkurs Eulen',
                                                start: new Date(2025, 2, 14, 16, 0),
                                                end: new Date(2025, 2, 14, 18,0),
                                                allDay: false,
                                                resource: { id: 1, details: 'Keramikkurs Eulen' },
                                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-eule.jpeg',
                                                hexColor:'#800080',
                                                description:`
                                                Fertige kleine Keramikeulen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine süße Nachteule oder einen kleinen Kautz ganz nach deinem Geschmack formen. Ich zeige Dir wie... 
  Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                `,
                                                price: '39,00',
                                                restAmount:10,
                                              },
                                              {
                                                id:"893",
                                                author: "Kathrin",
                                                title: 'Keramikkurs Eulen',
                                                  start: new Date(2025, 2, 20, 16, 0),
                                                  end: new Date(2025, 2, 13, 18,0),
                                                  allDay: false,
                                                  resource: { id: 1, details: 'Keramikkurs Eulen' },
                                                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-eule.jpeg',
                                                  hexColor:'#800080',
                                                  description:`
                                                  Fertige kleine Keramikeulen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine süße Nachteule oder einen kleinen Kautz ganz nach deinem Geschmack formen. Ich zeige Dir wie... 
    Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                  `,
                                                  price: '39,00',
                                                  restAmount:10,
                                                },
                                                {
                                                  id:"894",
                                                  author: "Kathrin",
                                                  title: 'Keramikkurs Eulen',
                                                    start: new Date(2025, 2, 21, 16, 0),
                                                    end: new Date(2025, 2, 21, 18,0),
                                                    allDay: false,
                                                    resource: { id: 1, details: 'Keramikkurs Eulen' },
                                                    titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-eule.jpeg',
                                                    hexColor:'#800080',
                                                    description:`
                                                    Fertige kleine Keramikeulen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine süße Nachteule oder einen kleinen Kautz ganz nach deinem Geschmack formen. Ich zeige Dir wie... 
      Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                    `,
                                                    price: '39,00',
                                                    restAmount:10,
                                                  },
                                            {
                                              id:"881",
                                              author: "Kathrin",
                                              title: 'Keramikkurs Osterhasen',
                                                start: new Date(2025, 3, 3, 16, 0),
                                                end: new Date(2025, 3, 3, 18,0),
                                                allDay: false,
                                                resource: { id: 1, details: 'Keramikkurs Osterhasen' },
                                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-hase.jpeg',
                                                hexColor:'#800080',
                                                description:`
                                                Fertige kleine Osterhasen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine kleine Hasenfamilie töpfern, die zu Ostern durch deinen Garten hoppelt oder deiner Fensterbank ein österliches Aussehen verleiht. Ich zeige dir wie... 
Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                `,
                                                price: '39,00',
                                                restAmount:10,
                                              },
                                              {
                                                id:"882",
                                                author: "Kathrin",
                                                title: 'Keramikkurs Osterhasen',
                                                  start: new Date(2025, 3, 4, 18, 0),
                                                  end: new Date(2025, 3, 4, 20,0),
                                                  allDay: false,
                                                  resource: { id: 1, details: 'Keramikkurs Osterhasen' },
                                                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-hase.jpeg',
                                                  hexColor:'#800080',
                                                  description:`
                                                  Fertige kleine Osterhasen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine kleine Hasenfamilie töpfern, die zu Ostern durch deinen Garten hoppelt oder deiner Fensterbank ein österliches Aussehen verleiht. Ich zeige dir wie... 
  Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                  `,
                                                  price: '39,00',
                                                  restAmount:10,
                                                },
                                                {
                                                  id:"883",
                                                  author: "Kathrin",
                                                  title: 'Keramikkurs Osterhasen',
                                                    start: new Date(2025, 3, 7, 16, 0),
                                                    end: new Date(2025, 3, 7, 18,0),
                                                    allDay: false,
                                                    resource: { id: 1, details: 'Keramikkurs Osterhasen' },
                                                    titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-hase.jpeg',
                                                    hexColor:'#800080',
                                                    description:`
                                                    Fertige kleine Osterhasen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine kleine Hasenfamilie töpfern, die zu Ostern durch deinen Garten hoppelt oder deiner Fensterbank ein österliches Aussehen verleiht. Ich zeige dir wie... 
    Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                    `,
                                                    price: '39,00',
                                                    restAmount:10,
                                                  },
                                                  {
                                                    id:"884",
                                                    author: "Kathrin",
                                                    title: 'Keramikkurs Osterhasen',
                                                      start: new Date(2025, 3, 8, 16, 0),
                                                      end: new Date(2025, 3, 8, 18,0),
                                                      allDay: false,
                                                      resource: { id: 1, details: 'Keramikkurs Osterhasen' },
                                                      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop-hase.jpeg',
                                                      hexColor:'#800080',
                                                      description:`
                                                      Fertige kleine Osterhasen für dich, für einen lieben Menschen oder als Mitbringsel. Mit einigen Tricks kannst du eine kleine Hasenfamilie töpfern, die zu Ostern durch deinen Garten hoppelt oder deiner Fensterbank ein österliches Aussehen verleiht. Ich zeige dir wie... 
      Kaffee, Tee und Mineralwasser stehen inklusive zur Verfügung. Weiter terminliche und inhaltliche Absprachen können gern getroffen werden.
                                                      `,
                                                      price: '39,00',
                                                      restAmount:10,
                                                    },
                                        {
                                          id:"439",
                                          author: "Nicolle",
                                          title: 'Gestaltung Makramee-Kranz',
                                            start: new Date(2024, 11, 17, 17, 0),
                                            end: new Date(2024, 11, 17, 19,0),
                                            allDay: false,
                                            resource: { id: 1, details: 'Gestaltung Makramee-Kranz' },
                                            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop_kranz.jpeg',
                                            hexColor:'#2F4F4F',
                                            description:`
                                            In gemütlicher Runde fertigt jeder seinen eigenen Makramee-Kranz. 

Alle benötigen Materialien wie hochwertiges Makrameegarn, und zum Dekorieren Holzperlen,  Naturmaterial sowie einzigartige Gussteile aus meiner Werkstatt werden gestellt und sind inklusive. 

Zum Workshop stehen kleine Snacks, Tee und Mineralwasser kostenlos zur Verfügung. 

Ein schöner Nachmittag/ Abend für Vereine,  Teams,  Freunde und alle "Kreativies".

Ich freue mich auf euch!
                                            `,
                                            price: '40,00',
                                            restAmount:10,
                                          },
                                          {
                                            id:"4323",
                                            author: "Marit",
                                            title: 'Kunst und Kaffee',
                                              start: new Date(2025, 1, 8, 15, 0),
                                              end: new Date(2025, 1, 8, 17,0),
                                              allDay: false,
                                              resource: { id: 1, details: 'Kunst und Kaffee' },
                                              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/workshop_marit.jpeg',
                                              hexColor:'#2F4F4F',
                                              description:`
                                              Kleiner Zeichen- Workshop mit der Künstlerin Marit Symalla.


Ich zeige euch wie ihr euer eigenes kleines Kunstwerk schaffen könnt und dabei genießen wir einen Kaffee oder Tee und was kleines zu naschen.





Inhalt dieses Workshops:
- grobe Zeichnung mit Buntstiften, Acryl- und Kohlestiften
- Motivauswahl und Aufbau auf dem Blatt
- Erklärung Kaffee, Kohle und Buntstifte, Wirkung aufeinander
- Auswahl der Materialien





Aufbau des Workshops:

Ich bringe fertige Acrylpapiere für die Zeichnung mit und auch unvorbereitete. Das heißt, da der Kaffee einige Zeit an Trocknung braucht, bis man auf dem Papier zeichnen kann, zeige ich euch wie ihr das auch zuhause vorbereiten könnt.

An dem Tag des Workshops, bringe ich bereits fertig bearbeitete Acrylpapiere mit Kaffee in den Größen Din A4 und A5 mit, damit wir gleich mit den Zeichnungen beginnen können. Wenn Ihr ein Lieblingsmotiv habt, bringt davon gerne Handyfotos mit. Vorzugsweise sind es kleinere Tiere oder auch Pflanzen wie Insekten, kleine Säugetiere und/oder Blumen und Gräser. Falls ihr keine direkten Wunschmotive habt, ist das auch nicht schlimm, denn ich bringe auch eine Auswahl von geeigneten Motiven mit.





Wer Fragen dazu hat, kann mir im Vorfeld gerne eine E-Mail an info@marit-symalla.de schreiben.
                                              `,
                                              price: '45,00',
                                              restAmount:8,
                                            },
                                            {
                                              id:"43231",
                                              author: "Simone",
                                              title: '3-D Bilder',
                                                start: new Date(2025, 2, 11, 18, 0),
                                                end: new Date(2025, 2, 11, 20,0),
                                                allDay: false,
                                                resource: { id: 1, details: '3-D Bilder' },
                                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/3Dworkshop.jpeg',
                                                hexColor:'#FF8C00',
                                                description:`
                                               Gestaltet euer ganz persönliches 3-D-Bild mit zauberhaften Motiven in großer Auswahl. Egal ob florales Design, kleine Landschaft, Blumenwiese mit Schmetterlingen und Blüten…farbenfroh oder Ton in Ton…alles ist möglich. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es anfertigen. Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre erwartet euch ein Abend für alle, die Freude am kreativen Gestalten haben.


Nutzung der Stanzen, Material und 2 Rahmen sind inclusive. Jeder weitere Rahmen wird mit 5,-€ berechnet.


Auch für Kinder (ab 10 Jahren) möglich.


Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                `,
                                                price: '39,00',
                                                restAmount:12,
                                              },
                                              {
                                                id:"43232",
                                                author: "Simone",
                                                title: '3-D Bilder',
                                                  start: new Date(2025, 1, 25, 18, 0),
                                                  end: new Date(2025, 1, 25, 20,0),
                                                  allDay: false,
                                                  resource: { id: 1, details: '3-D Bilder' },
                                                  titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/3Dworkshop.jpeg',
                                                  hexColor:'#FF8C00',
                                                  description:`
                                                 Gestaltet euer ganz persönliches 3-D-Bild mit zauberhaften Motiven in großer Auswahl. Egal ob florales Design, kleine Landschaft, Blumenwiese mit Schmetterlingen und Blüten…farbenfroh oder Ton in Ton…alles ist möglich. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es anfertigen. Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre erwartet euch ein Abend für alle, die Freude am kreativen Gestalten haben.
  
  
  Nutzung der Stanzen, Material und 2 Rahmen sind inclusive. Jeder weitere Rahmen wird mit 5,-€ berechnet.
  
  
  Auch für Kinder (ab 10 Jahren) möglich.
  
  
  Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                  `,
                                                  price: '39,00',
                                                  restAmount:12,
                                                },
                                                {
                                                  id:"43233",
                                                  author: "Simone",
                                                  title: '3-D Bilder',
                                                    start: new Date(2025, 1, 4, 18, 0),
                                                    end: new Date(2025, 1, 4, 20,0),
                                                    allDay: false,
                                                    resource: { id: 1, details: '3-D Bilder' },
                                                    titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/3Dworkshop.jpeg',
                                                    hexColor:'#FF8C00',
                                                    description:`
                                                   Gestaltet euer ganz persönliches 3-D-Bild mit zauberhaften Motiven in großer Auswahl. Egal ob florales Design, kleine Landschaft, Blumenwiese mit Schmetterlingen und Blüten…farbenfroh oder Ton in Ton…alles ist möglich. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es anfertigen. Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre erwartet euch ein Abend für alle, die Freude am kreativen Gestalten haben.
    
    
    Nutzung der Stanzen, Material und 2 Rahmen sind inclusive. Jeder weitere Rahmen wird mit 5,-€ berechnet.
    
    
    Auch für Kinder (ab 10 Jahren) möglich.
    
    
    Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                    `,
                                                    price: '39,00',
                                                    restAmount:12,
                                                  },
                                                  {
                                                    id:"43234",
                                                    author: "Simone",
                                                    title: '3-D Bilder',
                                                      start: new Date(2025, 3, 1, 18, 0),
                                                      end: new Date(2025, 3, 1, 20,0),
                                                      allDay: false,
                                                      resource: { id: 1, details: '3-D Bilder' },
                                                      titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/3Dworkshop.jpeg',
                                                      hexColor:'#FF8C00',
                                                      description:`
                                                     Gestaltet euer ganz persönliches 3-D-Bild mit zauberhaften Motiven in großer Auswahl. Egal ob florales Design, kleine Landschaft, Blumenwiese mit Schmetterlingen und Blüten…farbenfroh oder Ton in Ton…alles ist möglich. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es anfertigen. Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre erwartet euch ein Abend für alle, die Freude am kreativen Gestalten haben.
      
      
      Nutzung der Stanzen, Material und 2 Rahmen sind inclusive. Jeder weitere Rahmen wird mit 5,-€ berechnet.
      
      
      Auch für Kinder (ab 10 Jahren) möglich.
      
      
      Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                      `,
                                                      price: '39,00',
                                                      restAmount:12,
                                                    },
                                                    {
                                                      id:"432341",
                                                      author: "Simone",
                                                      title: '3-D Bilder',
                                                        start: new Date(2025, 4, 6, 18, 0),
                                                        end: new Date(2025, 4, 6, 20,0),
                                                        allDay: false,
                                                        resource: { id: 1, details: '3-D Bilder' },
                                                        titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/3Dworkshop.jpeg',
                                                        hexColor:'#FF8C00',
                                                        description:`
                                                       Gestaltet euer ganz persönliches 3-D-Bild mit zauberhaften Motiven in großer Auswahl. Egal ob florales Design, kleine Landschaft, Blumenwiese mit Schmetterlingen und Blüten…farbenfroh oder Ton in Ton…alles ist möglich. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es anfertigen. Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre erwartet euch ein Abend für alle, die Freude am kreativen Gestalten haben.
        
        
        Nutzung der Stanzen, Material und 2 Rahmen sind inclusive. Jeder weitere Rahmen wird mit 5,-€ berechnet.
        
        
        Auch für Kinder (ab 10 Jahren) möglich.
        
        
        Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                        `,
                                                        price: '39,00',
                                                        restAmount:12,
                                                      },
                                                    {
                                                      id:"4323512",
                                                      author: "Simone",
                                                      title: 'Stimmungslichter',
                                                        start: new Date(2025, 1, 6, 18, 0),
                                                        end: new Date(2025, 1, 6, 20,0),
                                                        allDay: false,
                                                        resource: { id: 1, details: 'Stimmungslichter' },
                                                        titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/StimmungslichterWorkshop.jpeg',
                                                        hexColor:'#FF8C00',
                                                        description:`
                                                    Gestaltet eure ganz persönlichen Stimmungslichter mit einer großen Auswahl an wunderschönen, individuellen Motiven. Die Lichter können in zwei verschiedenen Größen angefertigt werden, so dass man auch ein kleines Set zusammenstellen kann. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es herstellen! Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre zeige ich euch, wie diese zauberhaften Lichter angefertigt werden.


Nutzung der Stanzen, Material und 3 LEDs sind inklusive.


Die Veranstaltung ist auch für Kinder (ab 11 Jahre) möglich.


Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                        `,
                                                        price: '39,00',
                                                        restAmount:12,
                                                      },
                                                      {
                                                        id:"43236",
                                                        author: "Simone",
                                                        title: 'Stimmungslichter',
                                                          start: new Date(2025, 1, 27, 18, 0),
                                                          end: new Date(2025, 1, 27, 20,0),
                                                          allDay: false,
                                                          resource: { id: 1, details: 'Stimmungslichter' },
                                                          titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/StimmungslichterWorkshop.jpeg',
                                                          hexColor:'#FF8C00',
                                                          description:`
                                                      Gestaltet eure ganz persönlichen Stimmungslichter mit einer großen Auswahl an wunderschönen, individuellen Motiven. Die Lichter können in zwei verschiedenen Größen angefertigt werden, so dass man auch ein kleines Set zusammenstellen kann. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es herstellen! Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre zeige ich euch, wie diese zauberhaften Lichter angefertigt werden.
  
  
  Nutzung der Stanzen, Material und 3 LEDs sind inklusive.
  
  
  Die Veranstaltung ist auch für Kinder (ab 11 Jahre) möglich.
  
  
  Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                          `,
                                                          price: '39,00',
                                                          restAmount:12,
                                                        },
                                                        {
                                                          id:"43237",
                                                          author: "Simone",
                                                          title: 'Stimmungslichter',
                                                            start: new Date(2025, 2, 12, 18, 0),
                                                            end: new Date(2025, 2, 12, 20,0),
                                                            allDay: false,
                                                            resource: { id: 1, details: 'Stimmungslichter' },
                                                            titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/StimmungslichterWorkshop.jpeg',
                                                            hexColor:'#FF8C00',
                                                            description:`
                                                        Gestaltet eure ganz persönlichen Stimmungslichter mit einer großen Auswahl an wunderschönen, individuellen Motiven. Die Lichter können in zwei verschiedenen Größen angefertigt werden, so dass man auch ein kleines Set zusammenstellen kann. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es herstellen! Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre zeige ich euch, wie diese zauberhaften Lichter angefertigt werden.
    
    
    Nutzung der Stanzen, Material und 3 LEDs sind inklusive.
    
    
    Die Veranstaltung ist auch für Kinder (ab 11 Jahre) möglich.
    
    
    Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                            `,
                                                            price: '39,00',
                                                            restAmount:12,
                                                          },
                                                          {
                                                            id:"43235",
                                                            author: "Simone",
                                                            title: 'Stimmungslichter',
                                                              start: new Date(2025, 3, 2, 18, 0),
                                                              end: new Date(2025, 3, 2, 20,0),
                                                              allDay: false,
                                                              resource: { id: 1, details: 'Stimmungslichter' },
                                                              titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/StimmungslichterWorkshop.jpeg',
                                                              hexColor:'#FF8C00',
                                                              description:`
                                                          Gestaltet eure ganz persönlichen Stimmungslichter mit einer großen Auswahl an wunderschönen, individuellen Motiven. Die Lichter können in zwei verschiedenen Größen angefertigt werden, so dass man auch ein kleines Set zusammenstellen kann. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es herstellen! Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre zeige ich euch, wie diese zauberhaften Lichter angefertigt werden.
      
      
      Nutzung der Stanzen, Material und 3 LEDs sind inklusive.
      
      
      Die Veranstaltung ist auch für Kinder (ab 11 Jahre) möglich.
      
      
      Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                              `,
                                                              price: '39,00',
                                                              restAmount:12,
                                                            },
                                                            {
                                                              id:"432358",
                                                              author: "Simone",
                                                              title: 'Stimmungslichter',
                                                                start: new Date(2025, 4, 7, 18, 0),
                                                                end: new Date(2025, 4, 7, 20,0),
                                                                allDay: false,
                                                                resource: { id: 1, details: 'Stimmungslichter' },
                                                                titleImageUrl: process.env.REACT_APP_PUBLIC_URL +'/Workshop/StimmungslichterWorkshop.jpeg',
                                                                hexColor:'#FF8C00',
                                                                description:`
                                                            Gestaltet eure ganz persönlichen Stimmungslichter mit einer großen Auswahl an wunderschönen, individuellen Motiven. Die Lichter können in zwei verschiedenen Größen angefertigt werden, so dass man auch ein kleines Set zusammenstellen kann. Oder vielleicht sucht ihr noch ein originelles, ganz persönliches Geschenk? Hier könnt ihr es herstellen! Bei Kaffee, Tee oder Limonade und gemütlicher Atmosphäre zeige ich euch, wie diese zauberhaften Lichter angefertigt werden.
        
        
        Nutzung der Stanzen, Material und 3 LEDs sind inklusive.
        
        
        Die Veranstaltung ist auch für Kinder (ab 11 Jahre) möglich.
        
        
        Möchten Sie die Veranstaltung als individuellen Termin für ihre Gruppe buchen? Kontaktieren Sie mich gerne!
                                                                `,
                                                                price: '39,00',
                                                                restAmount:12,
                                                              },
      // Füge hier weitere Events hinzu
    // Füge hier weitere Events hinzu
  ];
  export default events;